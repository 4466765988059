<template>


    <div>
        <b-card no-body class="mb-1 p-1">
            <div class="d-flex" style="gap: 5px;">
                <b-form-input placeholder="Find A Product" v-model="filters.search" @input="onSearch" />
                <b-button variant="primary" style="min-width: fit-content"
                    @click="$router.push({ name: 'Shop Orders' })">
                    Orders &nbsp;<feather-icon icon="ClockIcon" />
                </b-button>
                <b-button variant="success" style="min-width: fit-content" @click="$router.push({ name: 'Shop Cart' })">
                    Cart
                    <b-badge v-if="cartCount" variant="dark">{{ cartCount }}</b-badge>
                </b-button>
            </div>
        </b-card>
        <b-row>
            <b-col cols="4" v-for="product in products" :key="'product_' + product.sp_id">
                <b-card class="product-card">
                    <div class="product-img-container">

                        <b-carousel id="carousel-fade" fade indicators background="#ababab">
                            <b-carousel-slide v-for="image in product.images" :key="image.spi_id">
                                <img :src="FILESURL + image.spi_image" alt="" class="product-img rounded-sm">
                            </b-carousel-slide>
                        </b-carousel>
                    </div>
                    <b-row>
                        <b-col cols="6">

                            <h2 class="mt-2 mb-1 text-truncate">{{ product.sp_name }}</h2>

                            <div>
                                <small class="small-dots" v-html="product.sp_description" />
                            </div>
                        </b-col>
                        <b-col cols="6">

                            <div class="mt-2 mb-1 d-flex justify-content-between w-100 align-items-end">
                                <h3 class="text-center mt-2">{{ getSymbolFromCurrency(product.sp_currency) }} {{
                                    product.sp_amount }}</h3>
                                <b-button variant="primary" class="px-2" @click="() => addToCart(product)">
                                    Add To Cart
                                </b-button>
                                <!-- <feather-icon icon="ShoppingCartIcon" /> -->
                            </div>
                        </b-col>

                    </b-row>


                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import {
    BCard,
    BCol,
    BRow,
    BButton,
    BFormInput,
    BModal,
    BBadge,
    BCarousel,
    BCarouselSlide
} from "bootstrap-vue";
import AgentServices from "@/apiServices/AgentServices";
import getSymbolFromCurrency from "currency-symbol-map";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BASEURL, FILESURL } from "@/config/index";
import CommonServices from "@/apiServices/CommonServices";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        BCol,
        BRow,
        BBadge,
        BButton,
        BFormInput,
        BModal,
        ToastificationContent,
        BCarousel,
        BCarouselSlide
    },
    data() {
        return {
            BASEURL,
            FILESURL,
            products: [],
            filters: {
                search: ''
            },
            debounce: null,
            cartCount: null
        }
    },
    methods: {
        async getAllProducts() {
            try {
                const res = await AgentServices.getAllProducts(this.filters)
                if (res.data.status) {
                    this.products = res.data.data
                } else {
                    console.error("Could Not Get All Products");
                }
            } catch (error) {
                console.error(error, "Could Not Get All Products");
            }
        },
        onBuy(product) {
            this.$router.push({ name: 'Shop Checkout', params: { sp_id: product.sp_id } })
        },
        async addToCart(product) {
            const payload = {
                sp_id: product.sp_id,
            }
            const res = await CommonServices.saveCartItem(payload)
            if (res.data.status) {
                this.getCartItems()
                this.$toast({
                    component: ToastificationContentVue,
                    props: {
                        title: res.data.message || "Item added to cart",
                        icon: "EditIcon",
                        variant: "success",
                    },
                });
            }
        },
        async getCartItems() {
            const res = await CommonServices.getCartItems()
            if (res.data.status) {
                this.cartCount = res.data.data.count
            }
        },
        onChange() {
            this.$nextTick(() => {
                this.getAllProducts();
            });
        },
        onSearch() {
            if (this.debounce) {
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.onChange();
            }, 1000);
        },
        getSymbolFromCurrency
    },
    beforeMount() {
        this.getAllProducts()
        this.getCartItems()
    },
}
</script>

<style>
.product-card {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-img-container {
    flex: 1;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.product-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.carousel-inner {
    height: 250px;
}

.carousel-item {
    height: 300px;
    /* Adjust to your preferred height */
    overflow: hidden;
}

.product-card h2,
.product-card h3 {
    margin: 0;
}

.product-card h2 {
    font-size: 1.25rem;
}

.product-card h3 {
    font-size: 1.5rem;
}

.carousel-caption {
    position: relative;
    right: 0 !important;
    left: 0 !important;
}

.small-dots p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
